<template>
  <div>
    <div class="main" v-if="!isMobileDevice">
      <div style="margin-left: 80px; display: flex">
        <img
          @click="goindex()"
          src="@/assets/logo.png"
          style="width: 200px; height: 100px; object-fit: contain"
          alt=""
        />
      </div>
      <div
        style="
          font-size: 28px;
          width: 50%;
          font-weight: bold;
          margin: 0 auto;
          margin-top: 50px;
        "
      >
        购买时长：
      </div>
      <div
        style="width: 50%; margin: 40px auto; display: flex; flex-wrap: wrap"
      >
        <div
          style="
            width: 30%;
            margin-right: 10px;
            height: 80px;
            border: 2px solid #a29fed;
            border-radius: 6px;
            text-align: center;
            margin-bottom: 10px;
            cursor: pointer;
          "
          v-for="(item, index) in coinList"
          :key="index"
          @click="opendialog(item)"
        >
          <div
            style="
              width: 100%;
              text-align: center;
              font-weight: bold;
              font-size: 24px;
              color: #a29fed;
              height: 80px;
              line-height: 80px;
            "
          >
            {{ item.num }}个月
          </div>
        </div>
      </div>
    </div>
    <!-- phone -->
    <div v-else style="width: 100%">
      <div style="display: flex">
        <img
          src="@/assets/logo.png"
          style="width: 150px; height: 100px; object-fit: contain"
          alt=""
          @click="goindex()"
        />
      </div>
      <div
        style="
          font-size: 28px;
          width: 50%;
          font-weight: bold;
          margin: 0 auto;
          margin-top: 50px;
        "
      >
        购买时长：
      </div>
      <div
        style="width: 95%; margin: 40px auto; display: flex; flex-wrap: wrap"
      >
        <div
          style="
            width: 30%;
           margin: 0 auto;
            height: 80px;
            border: 2px solid #a29fed;
            border-radius: 6px;
            text-align: center;
            margin-bottom: 10px;
            cursor: pointer;
          "
          v-for="(item, index) in coinList"
          :key="index"
          @click="opendialogh5(item)"
        >
          <div
            style="
              width: 100%;
              text-align: center;
              font-weight: bold;
              font-size: 24px;
              color: #a29fed;
              height: 80px;
              line-height: 80px;
            "
          >
            {{ item.num }}个月
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="buyshow" width="30%">
      <div
        style="
          width: 100%;
          border-radius: 12px;
          background-color: #fff;
          margin: 20px auto;
          padding: 30px;
        "
      >
        <div class="lxwmtext">请选择服务</div>
        <div style="margin-top: 20px">
          <el-checkbox
            v-model="checked1"
            @change="changeCheck1"
            style="margin: 20px"
            label="代理权"
            border
          ></el-checkbox>
          <el-checkbox
            @change="changeCheck2"
            :disabled="!checked1"
            v-model="checked2"
            style="margin: 20px"
            label="工会"
            border
          ></el-checkbox>
        </div>
        <div
          v-if="checked1"
          style="
            width: 100%;
            margin-top: 20px;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
          "
        >
          共{{ allmoney }}元
        </div>
        <div style="width: 100%; margin-top: 20px; text-align: center">
          <el-button v-if="checked1" type="primary">立即购买</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- phone -->
    <el-dialog title="提示" :visible.sync="buyshowh5" width="90%">
      <div
        style="
          width: 80%;
          border-radius: 12px;
          background-color: #fff;
          margin: 20px auto;
          padding: 30px;
        "
      >
        <div class="lxwmtext">请选择服务</div>
        <div style="margin-top: 20px">
          <el-checkbox
            v-model="checked1"
            @change="changeCheck1"
            style="margin: 20px"
            label="代理权"
            border
          ></el-checkbox>
          <el-checkbox
            @change="changeCheck2"
            :disabled="!checked1"
            v-model="checked2"
            style="margin: 20px"
            label="工会"
            border
          ></el-checkbox>
        </div>
        <div
          v-if="checked1"
          style="
            width: 100%;
            margin-top: 20px;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
          "
        >
          共{{ allmoney }}元
        </div>
        <div style="width: 100%; margin-top: 20px; text-align: center">
          <el-button v-if="checked1" type="primary">立即购买</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobileDevice: false,
      checked1: false,
      checked2: false,
      buyshow: false,
      allmoney: 10,
      form: {
        name: "",
        phone: "",
      },
      coinList: [
        {
          num: 3,
          money: "300.00",
          money2: "200.00",
        },
        {
          num: 6,
          money: "600.00",
          money2: "500.00",
        },
        {
          num: 12,
          money: "1200.00",
          money2: "1100.00",
        },
      ],
      seleItem: null,
      buyshowh5:false
    };
  },
  methods: {
    opendialogh5(item){
        this.seleItem = item
        this.buyshowh5 = true
    },
    changeCheck1() {
      if (this.checked1 == false) {
        this.checked2 = false;
        this.allmoney = 0;
      } else {
        this.allmoney = 0;
        this.allmoney += Number(this.seleItem.money);
      }
    },
    changeCheck2() {
      if (this.checked2 == false) {
        this.checked2 = false;
        this.allmoney -= Number(this.seleItem.money2);
      } else {
        this.allmoney += Number(this.seleItem.money2);
      }
    },
    checkDeviceType() {
      if (window.innerWidth <= 640) {
        this.isMobileDevice = true;
      } else {
        this.isMobileDevice = false;
      }
    },
    opendialog(item) {
      this.checked1 = false;
      this.checked2 = false;
      this.seleItem = item;
      this.allmoney = 0;
      this.buyshow = true;
    },
    godaili() {
      this.$router.push("/daili");
    },
    onSubmit() {
      this.$router.push("/setpassword");
    },
    goindex() {
      this.$router.push("/");
    },
  },
  mounted() {
    this.checkDeviceType();
    window.addEventListener("resize", this.checkDeviceType);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDeviceType);
  },
};
</script>

<style scoped>
.yellwobg {
  width: 300px;
  height: 150px;
  margin: 0px auto;
  background: url("@/assets/daili.png") no-repeat;
  background-size: contain;
}
::v-deep .el-input__inner {
  border: 2px solid #ccc;
}
::v-deep .el-input__inner[type="number"] {
  padding-right: 0px;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  line-height: 1px !important;
}
::v-deep .el-input__inner[type="number"]::-webkit-inner-spin-button,
::v-deep .el-input__inner[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-dialog__header {
  display: none;
}
::v-deep .el-dialog__footer {
  display: none;
}
::v-deep .el-dialog__body {
  padding: 0px;
  border-radius: 12px;
  background-color: transparent !important;
}
::v-deep .el-dialog {
  border-radius: 12px;
  background-color: transparent !important;
  box-shadow: none !important;
}
.lxwmtext {
  font-weight: bold;
  font-size: 24px;
}
</style>
