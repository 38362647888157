<template>
  <div class="mainbag" :style="isMobileDevice ? 'width:100%' : 'width:468px'">
    <div style="display: flex">
      <img
        src="@/assets/logo.png"
        style="width: 150px; height: 100px; object-fit: contain"
        alt=""
      />
      <div
        style="
          padding-left: 20px;
          height: 100px;
          line-height: 100px;
          color: gray;
        "
      >
        当前登录状态:游客登录
      </div>
    </div>
    <div style="height: 100vh; margin: 0 auto">
      <div class="itemlist" v-if="allList.length != 0">
        <div class="itembox" v-for="(item, index) in allList" :key="index">
          <template v-if="index == 12 || index == 6">
            <div style="width: 100%; height: 250px">
              <div class="mypho" @click="looksm()">
                <div style="width: 100%; text-align: center; padding-top: 80px">
                  <img src="../assets/simi.png" alt="" />
                  <div
                    style="
                      width: 100%;
                      text-align: center;
                      font-size: 18px;
                      color: white;
                    "
                  >
                    私密照片
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div
              v-if="item.video_url.length == 0"
              style="width: 100%; height: 250px; position: relative"
            >
              <div
                v-if="item.audio_file.length != 0"
                style="
                  position: absolute;
                  bottom: 5px;
                  right: 5px;
                  width: 100px;
                  height: 30px;
                  background-color: #ffc2c6;
                  z-index: 3;
                  border-radius: 30px;
                  text-align: center;
                "
                @click="startmp3(item.audio_file)"
              >
                <img
                  src="@/assets/shengbo.png"
                  style="width: 40px; height: 28px"
                  alt=""
                />
              </div>
              <el-carousel height="250px" style="width: 100%">
                <el-carousel-item
                  v-for="(imgitem, imgindex) in item.thumbnailPicUrls"
                  :key="imgindex"
                >
                  <el-image
                    :preview-src-list="item.thumbnailPicUrls"
                    :src="imgitem"
                    @click="look()"
                    style="width: 100%; height: 250px"
                    fit="cover"
                    alt=""
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
            <div v-else style="width: 100%; height: 250px; position: relative">
              <i
                class="el-icon-video-play"
                style="
                  font-size: 90px;
                  color: #ccc;
                  display: block;
                  position: absolute;
                  z-index: 999;
                  left: 28%;
                  top: 32%;
                "
                @click="openvideo(item.video_url)"
              ></i>
              <el-image
                :src="item.cover_url"
                style="width: 100%; height: 250px"
                fit="cover"
                alt=""
              />
            </div>
          </template>
          <div style="padding: 15px 10px; width: 100%; display: flex">
            <el-avatar size="small" :src="item.avatar"></el-avatar>
            <div
              style="
                font-size: 12px;
                font-weight: bold;
                padding-left: 10px;
                padding-top: 5px;
              "
            >
              {{ item.user_nickname }}
            </div>
            <div style="padding-left: 10px">
              <el-button
                type="primary"
                size="small"
                @click="senmes(item)"
                style="
                  background: #ffc2c6;
                  border: 2px solid #ffc2c6;
                  color: black;
                "
                >发消息</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="videoshow" width="100%" top="0px">
      <div style="width: 100%; height: 95vh; position: relative">
        <i
          class="el-icon-circle-close"
          style="
            font-size: 48px;
            display: block;
            top: 10px;
            right: 10px;
            color: white;
            position: absolute;
            z-index: 9999;
          "
          @click="closevide()"
        ></i>
        <video
          v-if="nowvideo_url != null"
          ref="videoRef"
          :src="nowvideo_url"
          controls
          style="width: 100%; height: 95vh"
        ></video>
      </div>
    </el-dialog>
    <download ref="download"></download>
    <audio :src="audiosrc" style="display: none" ref="myaudio"></audio>
  </div>
</template>

<script>
import download from "@/components/download.vue";
export default {
  components: {
    download,
  },
  data() {
    return {
      isMobileDevice: false,
      allList: [],
      videoshow: false,
      nowvideo_url: null,
      timer1: null,
      timer2: null,
      timer3: null,
      timer4: null,
      lastScrollTop: 0,
      audiosrc: null,
    };
  },
  methods: {
    startmp3(url) {
      console.log("url", url);
      if (this.audiosrc != null && this.audiosrc == url) {
        this.audiosrc = null;
        this.$refs.myaudio.pause();
      } else {
        this.audiosrc = url;
        setTimeout(() => {
          this.$refs.myaudio.play();
        }, 200);
      }
    },
    stopmp3() {
      this.$refs.myaudio.pause();
    },
    looksm() {
      this.$refs.download.openshow();
    },
    handleScroll(e) {},
    getList() {
      this.$axios
        .get("https://api.meirenyin.net/api_v5/bzone_api/get_bzone_list")
        .then((res) => {
          this.allList = res.data.data.list;
          console.log("rrrr", this.allList);
        });
    },
    openvideo(url) {
      this.nowvideo_url = url;
      this.videoshow = true;
      setTimeout(() => {
        this.$refs.videoRef.play();
        setTimeout(() => {
          if (Math.random() < 0.2) {
            this.$refs.download.openshow();
          }
        }, 1000);
      }, 800);
    },
    closevide() {
      this.videoshow = false;
      setTimeout(() => {
        this.$refs.videoRef.pause();
      }, 800);
    },
    checkDeviceType() {
      if (window.innerWidth <= 640) {
        this.isMobileDevice = true;
      } else {
        this.isMobileDevice = false;
      }
    },
    look() {
      if (Math.random() < 0.2) {
        this.$refs.download.openshow();
      }
    },
    senmes(item) {
      this.$router.push(`/message?uid=${item.uid}`);
    },
  },
  mounted() {
    this.checkDeviceType();
    this.getList();
    window.addEventListener("resize", this.checkDeviceType);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDeviceType);
  },
};
</script>

<style scoped>
.itemlist {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.itembox {
  width: 47%;
  margin: 10px 5px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 2px 4px 6px white;
}
::v-deep .el-dialog__header {
  display: none;
}
::v-deep .el-dialog__footer {
  display: none;
}
::v-deep .el-dialog__body {
  padding: 0px;
  border-radius: 12px;
  background-color: transparent !important;
}
::v-deep .el-dialog {
  border-radius: 12px;
  background-color: transparent !important;
}
.mainbag {
  background: url("@/assets/callbag.png") no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  margin: 0 auto;
}
.mypho {
  width: 100%;
  height: 250px;
  border-radius: 12px;
  background: url("@/assets/myphoto.png") no-repeat;
  background-size: cover;
}
</style>
