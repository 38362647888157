<template>
  <div>
    <div class="toptab" v-if="userinfo != null">
      <i
        class="el-icon-arrow-left"
        style="color: black; font-size: 22px; display: block; margin-top: 15px"
        @click="goback()"
      ></i>
      <div
        style="width: 40px; height: 40px; padding-top: 10px; margin-left: 10px"
        @click="open()"
      >
        <el-avatar size="small" :src="userinfo.avatar"></el-avatar>
      </div>
      <div
        style="
          padding-left: 0px;
          font-weight: bold;
          font-size: 16px;
          padding-top: 12px;
        "
      >
        {{ toptext }}
      </div>
    </div>
    <div class="msgbox" id="scrollContainer" v-if="userinfo != null">
      <div class="tip">聊天对话框提示消息</div>
      <template v-if="msglist.length != 0">
        <div v-for="(message, index) in msglist" :key="index">
          <div class="chatitem">
            <div class="msgitem-left" v-if="message.flow == 'in'">
              <div class="leftavatar">
                <image
                  :src="message.avatar"
                  mode="aspectFit"
                  style="width: 45px; height: 45px; border-radius: 50%"
                >
                </image>
              </div>

              <div class="leftmsg" v-if="message.type == 'text'">
                {{ message.payload.text }}
                <div style="font-size: 12px; color: #ccc">
                  {{ message.time }}
                </div>
              </div>

              <div
                class="audiobox"
                @click="openradio()"
                v-if="message.type == 'audio' && !isaudio"
              >
                <img
                  src="../assets/yuyin.png"
                  style="width: 26px; object-fit: cover; margin-top: 3px"
                  alt=""
                />
                <div
                  style="padding-left: 20px; padding-top: 6px; font-weight: 500"
                >
                  7&nbsp;''
                </div>
              </div>

              <div
                class="audiobox"
                @click="openradio()"
                v-if="message.type == 'audio' && isaudio"
              >
                <img
                  src="../assets/videoing.gif"
                  style="
                    width: 80px;
                    object-fit: cover;
                    margin-top: 3px;
                    height: 26px;
                  "
                  alt=""
                />
              </div>
            </div>
            <div class="msgitem-right" v-if="message.flow == 'out'">
              <div class="rightmsg">
                {{ message.payload.text }}
                <div style="font-size: 12px; color: #ccc">
                  {{ message.time }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="msginput">
      <div class="msgtnbox">
        <div class="msgleft">
          <el-input
            @confirm="sendTextMessage()"
            v-model="msgvalue"
            height="70"
            style="width: 90%"
            :border="false"
            placeholder="发消息..."
          />
        </div>
        <div class="msgright">
          <el-button
            @click="sendTextMessage()"
            type="primary"
            style="
              width: 100%;
              height: 35px;
              background: #ffc2c6;
              border: 2px solid #ffc2c6;
              color: black;
            "
            >发送</el-button
          >
        </div>
      </div>
    </div>
    <audio ref="audioPlayer" style="display: none" :src="audioSrc"></audio>
    <download ref="download"></download>
    <div style="position: fixed;bottom: 150px;right: 5px;">
      <img style="width: 64px;height: 64px;object-fit: cover;" src="../assets/msgphone.png" @click="open()" alt="">
    </div>
  </div>
</template>

<script>
import download from "@/components/download.vue";
export default {
  components: {
    download,
  },
  data() {
    return {
      msgvalue: "",
      msglist: [],
      toptext: "",
      username: "",
      num: 0,
      audioSrc: require("@/assets/ok.mp3"),
      isaudio: false,
      uid: null,
      userinfo: null,
    };
  },
  methods: {
    goback() {
      this.$router.push("/h5");
    },
    openradio() {
      if (this.isaudio) {
        this.isaudio = false;
        this.$refs.audioPlayer.pause();
      } else {
        this.$refs.audioPlayer.play();
        this.isaudio = true;
      }
    },
    sendTextMessage() {
      let obj = {
        flow: "out",
        payload: {
          text: this.msgvalue,
        },
        time: this.$moment().format("HH:mm:ss"),
      };
      this.msgvalue = "";
      if (this.num == 0) {
        this.msglist.push(obj);
        this.faketip();
      } else if (this.num == 1) {
        this.msglist.push(obj);
        this.fakeAudio();
      } else {
        this.$refs.download.openshow();
      }
    },
    faketip() {
      setTimeout(() => {
        this.toptext = "对方正在输入中...";
        setTimeout(() => {
          let obj = {
            flow: "in",
            payload: {
              text: "哥哥你好呀",
            },
            avatar: this.userinfo.avatar,
            type: "text",
            time: this.$moment().format("HH:mm:ss"),
          };
          this.toptext = this.username;
          this.msglist.push(obj);
          this.num += 1;
          localStorage.setItem("vbbynum", 1);
        }, 2000);
      }, 1200);
    },
    fakeAudio() {
      setTimeout(() => {
        this.toptext = "对方正在说话中...";
        setTimeout(() => {
          this.$refs.download.openshow();
        }, 2500);
        setTimeout(() => {
          let obj = {
            flow: "in",
            type: "audio",
            avatar: this.userinfo.avatar,
            time: this.$moment().format("HH:mm:ss"),
          };
          this.toptext = this.username;
          this.msglist.push(obj);
          this.num += 1;
          localStorage.setItem("vbbynum", 2);
        }, 7500);
      }, 2000);
    },
    getuserinfo() {
      this.$axios
        .get(
          "http://api.meirenyin.net/api_v5/user_api/get_user_info?to_user_id=" +
            this.uid
        )
        .then((res) => {
          console.log("rrr", res);
          this.userinfo = res.data;
          this.username = res.data.user_nickname;
          this.toptext = res.data.user_nickname;
        });
    },
    onAudioEnded() {
      this.isaudio = false;
    },
    open() {
      this.$refs.download.openshow();
    },
  },
  mounted() {
    let vbnum = localStorage.getItem("vbbynum");
    if (vbnum == null) {
      this.num = 0;
      localStorage.setItem("vbbynum", 0);
    } else {
      this.num = vbnum;
    }
    this.$refs.audioPlayer.addEventListener("ended", this.onAudioEnded);
    this.uid = this.$route.query.uid;
    this.getuserinfo();
    if (Math.random() < 0.2) {
      this.$refs.download.openshow();
    }
  },
};
</script>

<style scoped>
.audiobox {
  width: 80px;
  background-color: #ffb7c5;
  border-radius: 6px;
  display: flex;
  padding: 8px 20px;
}
::v-deep .u-input {
  background-color: #f3f3f3;
  border-radius: 6px;
}

::v-deep .u-input__input {
  padding-left: 10px;
}

.toptab {
  width: 100%;
  padding-top: 8px;
  padding-left: 15px;
  padding-bottom: 15px;
  display: flex;
  background-color: white;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
}

.msgbox {
  width: 95%;
  margin: 0px auto;
  margin-top: 80px;
}

.time {
  width: 100%;
  text-align: center;
  color: #7a7979;
  font-size: 14px;
  margin: 15px auto;
}

.tip {
  width: 100%;
  text-align: center;
  color: #7a7979;
  font-size: 13px;
  margin: 30px auto;
  font-weight: bold;
}

.msginput {
  width: 95%;
  position: fixed;
  bottom: 0px;
  left: 2.5%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.msgtnbox {
  width: 100%;
  display: flex;
}
.msgleft {
  width: 80%;
}

.msgright {
  width: 20%;
  display: flex;
}

::v-deep .u-btn--primary {
  background-color: black;
  color: white;
}

.voiceBox {
  position: fixed;
  right: 5px;
}

.chatitem {
  display: flex;
  flex-direction: column;
}
.msgitem-left {
  width: 100%;
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}

.leftavatar {
  width: 45px;
  height: 45px;
}

.leftmsg {
  width: 80%;
  padding-left: 15px;
  font-weight: bold;
  padding-top: 10px;
  font-size: 17px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.msgitem-right {
  font-size: 17px;

  font-weight: bold;
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
  max-width: 95%;
}
.rightmsg {
  padding: 8px 15px;
  background-color: #428de3;
  color: white;
  border-radius: 6px;
}
</style>
