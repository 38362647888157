import Vue from 'vue';
import Router from 'vue-router';
import index from '../views/index.vue';
import daili from '../views/daili.vue';
import setpassword from '../views/setpassword.vue';
import recharge from '../views/recharge.vue';
import htmlq from '../views/htmlq.vue';
import message from '../views/message.vue';
Vue.use(Router);

export default new Router({
  routes: [
    { path: '/', component: index, meta: { title: '首页' } },
    {
      path: '/daili',
      component: daili
    },
    {
      path: '/setpassword',
      component: setpassword
    },
    {
      path: '/recharge',
      component: recharge
    },
    {
      path: '/h5',
      component: htmlq
    },
    {
      path: '/message',
      component: message
    }
  ],
});
