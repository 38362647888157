<template>
  <div>
    <div class="main" v-if="!isMobileDevice">
      <div style="margin-left: 80px; display: flex">
        <img
          @click="goindex()"
          src="@/assets/logo.png"
          style="width: 200px; height: 100px; object-fit: contain"
          alt=""
        />
      </div>
      <div class="yellwobg"></div>
      <div style="width: 30%; margin: 50px auto">
        <el-form
          ref="form"
          style="width: 100%"
          :model="form"
          label-width="80px"
        >
          <el-form-item label="手机号:">
            <el-input
              class="custom-input"
              v-model="form.phone"
              type="number"
              style="border-color: #ccc"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码:" style="width: 100%">
            <el-input
              v-model="form.name"
              style="border-color: #ccc; width: 50%"
            ></el-input>
            <el-button
              type="primary"
              style="
                background-color: #ffe03f;
                color: #333;
                border-color: #ffe03f;
                font-weight: bold;
                margin-left: 5px;
              "
              @click="onSubmit"
              >发送验证码</el-button
            >
          </el-form-item>
          <el-form-item style="width: 100%">
            <el-button
              type="primary"
              style="
                background-color: #ffe03f;
                color: #333;
                border-color: #ffe03f;
                margin: 0 auto;
                display: block;
                font-weight: bold;
                margin-top: 50px;
                width: 80%;
              "
              @click="onSubmit"
              >注册</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-else style="width: 100%">
      <div style="display: flex">
        <img
          src="@/assets/logo.png"
          style="width: 150px; height: 100px; object-fit: contain"
          alt=""
          @click="goindex()"
        />
      </div>
      <div style="width: 100%; margin-top: 0px">
        <img
          src="@/assets/daili.png"
          style="
            width: 200px;
            height: 120px;
            margin: 0 auto;
            display: block;
            object-fit: contain;
          "
          alt=""
        />
      </div>
      <div style="width: 90%; margin: 20px auto">
        <el-form
          ref="form"
          style="
            width: 90%;
            margin: 0 auto;
            margin-left: -10px;
            margin-top: 40px;
          "
          :model="form"
          label-width="80px"
        >
          <el-form-item label="手机号:">
            <el-input
              class="custom-input"
              v-model="form.phone"
              type="number"
              style="border-color: #ccc"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码:" style="width: 100%">
            <el-input
              v-model="form.name"
              style="border-color: #ccc; width: 50%"
            ></el-input>
            <el-button
              type="primary"
              style="
                background-color: #ffe03f;
                color: #333;
                border-color: #ffe03f;
                font-weight: bold;
                margin-left: 5px;
              "
              @click="onSubmit"
              >发送验证码</el-button
            >
          </el-form-item>
          <el-form-item style="width: 100%">
            <el-button
              type="primary"
              style="
                background-color: #ffe03f;
                color: #333;
                border-color: #ffe03f;
                margin: 0 auto;
                display: block;
                font-weight: bold;
                width: 50%;
                margin-top: 20px;
              "
              @click="onSubmit"
              >注册</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobileDevice: false,
      form: {
        name: "",
        phone: "",
      },
    };
  },
  methods: {
    checkDeviceType() {
      if (window.innerWidth <= 640) {
        this.isMobileDevice = true;
      } else {
        this.isMobileDevice = false;
      }
    },
    godaili() {
      this.$router.push("/daili");
    },
    onSubmit() {
      this.$router.push('/setpassword')
    },
    goindex() {
      this.$router.push("/");
    },
  },
  mounted() {
    this.checkDeviceType();
    window.addEventListener("resize", this.checkDeviceType);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDeviceType);
  },
};
</script>

<style scoped>
.yellwobg {
  width: 300px;
  height: 150px;
  margin: 0px auto;
  background: url("@/assets/daili.png") no-repeat;
  background-size: contain;
}
::v-deep .el-input__inner {
  border: 2px solid #ccc;
}
::v-deep .el-input__inner[type="number"] {
  padding-right: 0px;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  line-height: 1px !important;
}
::v-deep .el-input__inner[type="number"]::-webkit-inner-spin-button,
::v-deep .el-input__inner[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
