<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {

};
</script>

<style>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: transparent !important;
}
#app {
  /* min-height: 100vh; */
  height: 100vh;

}
::-webkit-scrollbar {
  display: none;
}
</style>
